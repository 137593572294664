<template>
  <user-chip-movements-card />
</template>
<script>
import UserChipMovementsCard from "./components/UserChipMovementsCard.vue";
export default {
  components: {
    UserChipMovementsCard,
  },
};
</script>
